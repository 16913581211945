@import 'npm:@fontsource-variable/inter/wght.css';
@import 'npm:@fontsource-variable/inter-tight/wght.css';
@import 'npm:@fontsource-variable/roboto-mono/wght.css';
@import 'npm:@fontsource-variable/noto-sans-arabic/wght.css';
@import 'npm:@fontsource-variable/noto-sans-devanagari/wght.css';
@import 'npm:@fontsource-variable/noto-sans-hebrew/wght.css';
@import 'npm:@fontsource-variable/noto-sans-jp/wght.css';
@import 'npm:@fontsource-variable/noto-sans-kr/wght.css';
@import 'npm:@fontsource-variable/noto-sans-sc/wght.css';
@import 'npm:@fontsource-variable/noto-sans-tc/wght.css';
@import 'npm:@fortawesome/fontawesome-free/css/all.css';

body {
  color-scheme: light dark;
}
body.theme-light, body.theme-system {
  --main-hue: 210; // bluish
  --ok-hue: 120; // green
  --warning-hue: 50; // yellow
  --danger-hue: 0; // red
  --body-bg-color: hsl(var(--main-hue), 10%, 60%);
  --main-color: hsl(var(--main-hue), 10%, 10%);
  --main-underline-color: hsl(var(--main-hue), 10%, 10%, 0.5);
  --ok-color: hsl(var(--ok-hue), 50%, 50%);
  --ok-bg-color: hsl(var(--ok-hue), 100%, 50%);
  --warning-bg-color: hsl(var(--warning-hue), 100%, 50%);
  --danger-color: hsl(var(--danger-hue), 60%, 40%);
  --selection-color: hsl(var(--main-hue), 10%, 95%);
  --selection-bg-color: hsl(var(--main-hue), 90%, 60%);
  --selection-danger-color: hsl(var(--danger-hue), 90%, 10%);
  --selection-danger-bg-color: hsl(var(--danger-hue), 90%, 40%);
  --sheet-bg-color: hsl(var(--main-hue), 10%, 80%);
  --panel-bg-color: hsl(var(--main-hue), 10%, 70%);
  --area-bg-color: hsl(var(--main-hue), 10%, 90%);
  --border-color: hsl(var(--main-hue), 10%, 20%, 0.3);
  --sheet-shadow: 0 4px 20px 0px #0003;
  --main-weak-color: hsl(var(--main-hue), 10%, 40%);
  --link-color: hsl(var(--main-hue), 100%, 30%);
  --link-hover-color: hsl(var(--main-hue), 100%, 50%);
  --input-bg-color: hsl(var(--main-hue), 50%, 95%);
  --input-shadow: 0 0 4px -2px hsl(var(--main-hue), 10%, 50%);
  --input-glow: 0 0 6px -2px hsl(var(--main-hue), 80%, 50%);
  --input-glow-danger: 0 0 6px -2px hsl(var(--danger-hue), 80%, 50%);
  --button-bg-color: hsl(var(--main-hue), 50%, 90%, 0.4);
  --list-hover-bg-color: hsl(var(--main-hue), 90%, 60%, 0.3);
  --progress-normal-color: hsl(var(--main-hue), 50%, 50%);
  --progress-normal-bg-color: hsl(var(--main-hue), 50%, 50%, 0.4);
  --progress-ok-color: #090;
  --progress-ok-bg-color: #00990080;
  --progress-error-color: #f00;
  --progress-error-bg-color: #ff000040;
  --error-color: hsl(var(--danger-hue), 90%, 10%);
  --button-active-bg-color: hsl(var(--main-hue), 80%, 90%, 0.2);
  --button-active-shadow-color: hsl(var(--main-hue), 80%, 50%);
  --button-active-danger-bg-color: hsl(var(--danger-hue), 80%, 90%, 0.1);
  --button-active-danger-shadow-color: hsl(var(--danger-hue), 80%, 50%);
}
@mixin theme-dark {
  --main-hue: 210; // bluish
  --ok-hue: 110; // green
  --warning-hue: 50; // yellow
  --danger-hue: 0; // red
  --body-bg-color: hsl(var(--main-hue), 10%, 10%);
  --main-color: hsl(var(--main-hue), 10%, 80%);
  --main-underline-color: hsl(var(--main-hue), 10%, 70%, 0.5);
  --ok-color: hsl(var(--ok-hue), 100%, 20%);
  --ok-bg-color: hsl(var(--ok-hue), 100%, 20%);
  --warning-bg-color: hsl(var(--warning-hue), 100%, 20%);
  --danger-color: hsl(var(--danger-hue), 60%, 60%);
  --selection-color: hsl(var(--main-hue), 10%, 90%);
  --selection-bg-color: hsl(var(--main-hue), 90%, 30%);
  --selection-danger-color: hsl(var(--danger-hue), 90%, 90%);
  --selection-danger-bg-color: hsl(var(--danger-hue), 90%, 40%);
  --sheet-bg-color: hsl(var(--main-hue), 10%, 20%);
  --panel-bg-color: hsl(var(--main-hue), 10%, 25%);
  --area-bg-color: hsl(var(--main-hue), 10%, 15%);
  --border-color: hsl(var(--main-hue), 50%, 40%, 0.3);
  --sheet-shadow: 0 4px 20px 0px hsl(var(--main-hue), 100%, 50%, 0.1);
  --main-weak-color: hsl(var(--main-hue), 10%, 60%);
  --link-color: hsl(var(--main-hue), 100%, 70%);
  --link-hover-color: hsl(var(--main-hue), 100%, 80%);
  --input-bg-color: hsl(var(--main-hue), 20%, 10%);
  --input-shadow: 0 0 4px -2px hsl(var(--main-hue), 10%, 50%);
  --input-glow: 0 0 6px -2px hsl(var(--main-hue), 80%, 60%);
  --input-glow-danger: 0 0 6px -2px hsl(var(--danger-hue), 80%, 80%);
  --button-bg-color: hsl(var(--main-hue), 40%, 20%);
  --list-hover-bg-color: hsl(var(--main-hue), 90%, 60%, 0.3);
  --progress-normal-color: hsl(var(--main-hue), 50%, 50%);
  --progress-normal-bg-color: hsl(var(--main-hue), 50%, 50%, 0.4);
  --progress-ok-color: #090;
  --progress-ok-bg-color: #00990080;
  --progress-error-color: #f00;
  --progress-error-bg-color: #ff000040;
  --error-color: hsl(var(--danger-hue), 90%, 10%);
  --button-active-bg-color: hsl(var(--main-hue), 50%, 30%);
  --button-active-shadow-color: hsl(var(--main-hue), 80%, 60%);
  --button-active-danger-bg-color: hsl(var(--danger-hue), 50%, 30%);
  --button-active-danger-shadow-color: hsl(var(--danger-hue), 80%, 50%);
}
body.theme-dark {
  @include theme-dark;
}
@media (prefers-color-scheme: dark) {
  body.theme-system {
    @include theme-dark;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow-block: scroll; // always show scroll to avoid relayouts because of appearing scrollbar
  background: var(--body-bg-color);
}

$system-font: system-ui;

@mixin font-family($main) {
  font-family: $main, $system-font;
  &:not(.icon) {
    &:lang(ar) {
      font-family: $main, 'Noto Sans Arabic Variable', $system-font;
    }
    &:lang(he) {
      font-family: $main, 'Noto Sans Hebrew Variable', $system-font;
    }
    &:lang(hi) {
      font-family: $main, 'Noto Sans Devanagari Variable', $system-font;
    }
    &:lang(ja) {
      font-family: $main, 'Noto Sans JP Variable', $system-font;
    }
    &:lang(ko) {
      font-family: $main, 'Noto Sans KR Variable', $system-font;
    }
    &:lang(zh-Hans) {
      font-family: $main, 'Noto Sans SC Variable', $system-font;
    }
    &:lang(zh-Hant) {
      font-family: $main, 'Noto Sans TC Variable', $system-font;
    }
  }
}

@mixin monospace-font {
  font-family: 'Roboto Mono Variable', monospace;
}

h3, p, div, span, button, input, a {
  color: var(--main-color);
  @include font-family('Inter Variable');
}
h1, h2, .app_name {
  color: var(--main-color);
  @include font-family('Inter Tight Variable');
}
code, pre {
  @include monospace-font;
  color: var(--main-color);
  font-size: 16px;
  font-weight: 450;
}
p, div, button, input {
  font-size: 16px;
  font-weight: 500;
}

h1 {
  font-size: 32px;
  font-weight: 400;
  margin-block: 10px 0;
  & > .hint {
    text-transform: uppercase;
    font-size: 16px;
    color: var(--main-weak-color);
  }
}
h2 {
  font-size: 24px;
  font-weight: 400;
  margin-block: 5px 0;
}
h3 {
  font-size: 20px;
  font-weight: 450;
  margin-block: 5px 0;
}
p {
  margin-block: 5px;
  line-height: 1.5;
}

a {
  text-decoration: none;
  text-decoration-skip-ink: none;
  &:not(.button) {
    color: var(--link-color);
    &:hover {
      color: var(--link-hover-color);
      text-decoration: underline;
    }
  }
  &[target='_blank'] {
    &::after {
      content: '↗';
      font-family: system-ui;
      opacity: 0.5;
      display: inline-block;
    }
    &:dir(rtl)::after {
      content: '↖';
    }
  }
}

strong {
  font-weight: 600;
}
h2 strong {
  font-weight: 500;
}

@mixin sheet-small {
  width: var(--sheet-small-width);
}
@mixin sheet-medium {
  width: var(--sheet-medium-width);
}
@mixin sheet-big {
  width: var(--sheet-big-width);
}
@mixin sheet-anysize {
  min-width: var(--sheet-big-width);
}
.sheet {
  background: var(--sheet-bg-color);
  padding: 0;
  border: 0.5px solid var(--border-color);
  overflow-x: clip;
  overflow-y: auto;
  &.small {
    @include sheet-small;
  }
  &.medium {
    @include sheet-medium;
  }
  &.big {
    @include sheet-big;
  }
  &.anysize {
    @include sheet-anysize;
  }
}
@mixin sheet-border {
  margin-block: 16px;
  border-radius: 16px;
  box-shadow: var(--sheet-shadow);
}
body {
  --sheet-small-width: 100vw;
  --sheet-medium-width: 100vw;
  --sheet-big-width: 100vw;
}
@media (min-width: 640px) {
  body {
    --sheet-small-width: 640px;
  }
  .sheet.small {
    @include sheet-border;
  }
}
@media (min-width: 768px) {
  body {
    --sheet-medium-width: 768px;
  }
  .sheet.medium {
    @include sheet-border;
  }
}
@media (min-width: 1024px) {
  body {
    --sheet-big-width: 900px;
  }
  .sheet.big {
    @include sheet-border;
  }
}

dialog.sheet {
  &::backdrop {
    backdrop-filter: blur(4px);
  }
}

.ui {
  display: grid;
  grid-template-columns:
    var(--ui-margin-inline-start)
    1fr
    var(--ui-margin-inline-end)
    ;
  place-content: start stretch;
  place-items: baseline stretch;
  gap: var(--ui-gap);
  --ui-margin-inline-start: 20px;
  --ui-margin-inline-end: 20px;
  --ui-gap: 10px;
  // default placement for stuff
  & > h1, & > h2, & > h3, & > p, & > ul, & > .buttons, & > .markdown {
    grid-column: 2 / -2;
  }
  & > .list {
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
    & > * {
      & > .empty, & > .loading, & > .error {
        grid-column: 2 / -2;
      }
      & > .empty, & > .loading {
        color: var(--main-weak-color);
      }
      & > .error {
        color: var(--error-color);
      }
    }
  }
  & > label {
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: subgrid;
    align-items: baseline;
    & > .label {
      grid-column: 1;
      white-space: nowrap;
    }
    & > .value {
      grid-column: 2 / -1;
    }
  }
  // header and footer
  & > .header, & > .footer {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    padding-block: 10px;
    & > * {
      grid-column: 2 / -2;
    }
  }
  & > .header {
    border-block-end: 0.5px solid var(--border-color);
  }
  & > .footer {
    background: var(--panel-bg-color);
    border-block-start: 0.5px solid var(--border-color);
  }
  & > .message {
    grid-column: 2 / -2;
  }
  & > .note {
    grid-column: 2 / -2;
    &, & * {
      color: var(--main-weak-color);
    }
  }
  & > .loading {
    grid-column: 2 / -2;
    color: var(--main-weak-color);
  }
  & > .highlight {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    background: var(--area-bg-color);
    border-block: 0.5px solid var(--border-color);
    & > * {
      grid-column: 2 / -2;
    }
    &.ok {
      background: var(--ok-bg-color);
    }
    &.warning {
      background: var(--warning-bg-color);
    }
    &.error {
      background: var(--error-color);
    }
  }
  & .breadcrumbs {
    grid-column: 2 / -2;
    display: flex;
    flex-wrap: wrap;
    place-items: baseline stretch;
    gap: 4px;
  }
  &.page_not_found {
    & > .items {
      grid-column: 2 / -2;
    }
  }
  // specific UIs
  &.dialog_ok {
  }
  &.dialog_ok_copy {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      min-content
      var(--ui-margin-inline-end)
      ;
    input[type='text'] {
      grid-column: 2;
    }
    .button {
      grid-column: 3;
    }
  }
  &.dialog_confirm {
  }
  &.dialog_prompt {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .input {
      grid-column: 2 / -2;
      display: grid;
      grid-template-columns: subgrid;
      align-items: baseline;
      & > .label {
        white-space: nowrap;
      }
    }
  }
  &.dialog_prompt_markdown {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .input {
      grid-column: 2 / -2;
      & > textarea {
        @include monospace-font;
        font-size: 16px;
      }
    }
  }
  &.dialog_error {
  }
  &.home {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .links {
      grid-column: 2 / -2;
      display: grid;
      grid-template-columns: subgrid;
      gap: 4px;
      & > a {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline stretch;
        background: var(--area-bg-color);
        padding: 8px;
        border: 0.5px solid var(--border-color);
        border-radius: 4px;
        &:hover {
          text-decoration: none;
          & > .title {
            text-decoration: underline;
          }
          & > .icon {
            text-shadow: 0 0 2px var(--button-active-shadow-color);
          }
        }
        & > .icon {
          grid-column: 1;
          grid-row: span 2;
          place-self: center;
        }
        & > .title {
          grid-column: 2;
          font-size: 20px;
        }
        & > .hint {
          grid-column: 2;
          opacity: 0.7;
        }
      }
    }
  }
  &.projects {
    & > .list.projects {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        align-items: baseline;
        & > .title {
          grid-column: 2;
        }
      }
    }
  }
  &.project {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      1fr
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .list.branches {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        align-items: baseline;
        & > .title {
          grid-column: 2;
          font-weight: 600;
        }
        & > .current_version {
          grid-column: 3;
        }
        & > .current_version_published {
          grid-column: 4;
          color: var(--main-weak-color);
        }
      }
    }
  }
  &.project_management {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .list.project_users {
      & > * { // project_user
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .icon {
          grid-column: 2;
          place-self: center;
          color: var(--main-weak-color);
        }
        & > .title {
          grid-column: 3;
        }
        & > .role {
          grid-column: 4;
          color: var(--main-weak-color);
        }
        & > .empty, & > .loading, & > .error {
          grid-column: 3 / -2;
        }
        & > .footer {
          grid-column: 3 / -2;
        }
      }
    }
    & > .list.deploy_sites {
      & > * { // deploy_site
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          place-self: center;
          color: var(--main-weak-color);
        }
        & > .site {
          grid-column: 3;
        }
        & > .provider {
          grid-column: 3;
          white-space: nowrap;
          color: var(--main-weak-color);
        }
        & > .buttons {
          grid-column: 4;
          grid-row: span 2;
          place-self: center start;
        }
        & > .empty, & > .loading, & > .error {
          grid-column: 3 / -2;
        }
        & > .footer {
          grid-column: 3 / -2;
        }
      }
    }
    & > .list.public_access {
      & > * { // single row
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          place-self: center;
          color: var(--main-weak-color);
        }
        & > .message {
          grid-column: 3 / -2;
        }
        & > .note {
          grid-column: 3 / -2;
          color: var(--main-weak-color);
        }
        & > .buttons {
          grid-column: 3 / -2;
        }
        & > h3 {
          grid-column: 3 / -2;
        }
        & > .branch {
          grid-column: 3 / -2;
        }
        & > .footer {
          grid-column: 3 / -2;
          margin-block-start: 8px;
        }
        & > .store_link {
          grid-column: 3 / -2;
        }
      }
    }
    & > .buttons {
      grid-column: 3 / -2;
    }
  }
  &.branch {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      min-content
      min-content
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .current_version {
      grid-column: 2 / -2;
      font-size: 24px;
    }
    & > .list.branch_versions {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        align-items: baseline;
        & > .title {
          grid-column: 3;
          font-weight: 600;
        }
        & > .hint {
          grid-column: 4 / 6;
          color: var(--main-weak-color);
        }
        & > .published {
          grid-column: 6;
          white-space: nowrap;
          color: var(--main-weak-color);
        }
        & > .message {
          grid-column: 2 / -2;
        }
      }
    }
  }
  &.version {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      min-content
      min-content
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .list.branches {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        align-items: baseline;
        & > .title {
          grid-column: 3;
          font-weight: 600;
        }
        & > .hint {
          grid-column: 4 / 6;
        }
        & > .published {
          grid-column: 6;
          white-space: nowrap;
          color: var(--main-weak-color);
        }
      }
    }
  }
  &.branch, &.version {
    & > .list.packages {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        grid-row-gap: 4px;
        align-items: baseline;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          align-self: center;
        }
        & > .title {
          grid-column: 3;
          font-weight: 600;
        }
        & > .size_count {
          grid-column: 4;
          justify-self: end;
        }
        & > .size_suffix {
          grid-column: 5;
          justify-self: start;
          opacity: 0.7;
        }
        & > .published {
          grid-column: 6;
          white-space: nowrap;
          color: var(--main-weak-color);
        }
        & > .tags {
          grid-column: 3;
        }
        & > .buttons {
          grid-column: 4 / 7;
        }
      }
    }
  }
  &.package {
    & > .tags {
      grid-column: 2 / -2;
    }
    & > .message {
      & > .loading, & > .error {
        color: var(--main-weak-color);
      }
    }
    .upload-box {
      grid-column: 1 / -1;
    }
    .size {
      grid-column: 2 / -2;
    }
  }
  &.package_play {
    @include sheet-big;
    & > iframe {
      grid-column: 1 / -1;
      background: var(--area-bg-color);
      border-block: 0.5px solid var(--border-color);
      border-inline: none;
      overflow: hidden;
      min-height: 70vh;
    }
  }
  &.package_cloud_play {
    @include sheet-anysize;
    & > video {
      grid-column: 2 / -2;
      aspect-ratio: 16 / 9 auto;
      background-color: #000;
      border: 0.5px solid var(--border-color);
      &:focus {
        outline: none;
      }
    }
  }
  &.local {
    @include sheet-medium;
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      max-content
      var(--ui-margin-inline-end)
      ;
    & > .list.workdirs {
      & > * { // workdir
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .header, & > .branch, & > .version {
          grid-column: 2 / -2;
        }
        & > .breadcrumbs {
          grid-column: 2 / -3;
        }
        & > .buttons {
          grid-column: -3;
        }
        & > .local_path {
          grid-column: 2 / -2;
          color: var(--main-weak-color);
        }
      }
    }
    & > .list.workspaces {
      & > * { // workspace
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .local_path {
          grid-column: 2;
          color: var(--main-weak-color);
        }
      }
    }
  }
  &.new_version {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      auto
      auto
      min-content
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .list.packages {
      & > * { // package
        display: grid;
        grid-template-columns: subgrid;
        row-gap: 4px;
        place-items: baseline start;
        & > .title {
          grid-column: 2 / 4;
          font-weight: 600;
        }
        & > .buttons {
          grid-column: 2 / 4;
          & > .note {
            color: var(--main-weak-color);
          }
        }
        & > .tags {
          grid-column: 4;
        }
        & > .status, & > .part_progress {
          grid-column: 2 / -2;
          justify-self: stretch;
          color: var(--main-weak-color);
        }
        & > .size_count {
          grid-column: 5;
          justify-self: end;
        }
        & > .size_suffix {
          grid-column: 6;
          justify-self: start;
          opacity: 0.7;
        }
        & > .button {
          grid-column: -1;
          align-self: center;
          visibility: hidden;
        }
        &:hover > .button {
          visibility: visible;
        }
      }
    }
  }
  &.select_branch {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .list.branches {
      & > * { // branch
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        &.current {
          cursor: not-allowed;
          & > * {
            color: var(--main-weak-color);
          }
        }
        & > .title {
          grid-column: 2;
          font-weight: 600;
        }
        & > .note {
          grid-column: 3;
        }
      }
    }
  }
  &.select_existing_version {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .list.versions {
      & > * { // version
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        &.current {
          cursor: not-allowed;
          & > * {
            color: var(--main-weak-color);
          }
        }
        & > .title {
          grid-column: 2;
          font-weight: 600;
        }
        & > .created {
          grid-column: 3;
        }
      }
    }
  }
  &.new_package {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .tags {
      grid-column: 2 / -2;
    }
    & > .size {
      grid-column: 2 / -2;
      & > .note {
        &, & * {
          color: var(--main-weak-color);
        }
      }
    }
    & > .upload-box {
      grid-column: 1 / -1;
    }
    & > .checks {
      grid-column: 2 / -2;
      display: grid;
      grid-template-columns: 1fr min-content;
      & > * {
        grid-column: 1 / -1;
        display: grid;
        grid-template-columns: subgrid;
        gap: 4px;
        place-items: baseline start;
        & > .message {
          grid-column: 1;
        }
        & > .buttons {
          grid-column: 2;
        }
      }
    }
  }
  &.add_existing_package {
    grid-template-columns:
      var(--ui-margin-inline-start)
      auto
      auto
      min-content
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .list.packages {
      & > * { // package
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        cursor: pointer;
        user-select: none;
        & > .title {
          grid-column: 2;
          font-weight: 600;
        }
        & > .tags {
          grid-column: 3;
        }
        & > .size_count {
          grid-column: 4;
          justify-self: end;
        }
        & > .size_suffix {
          grid-column: 5;
          justify-self: start;
          opacity: 0.7;
        }
      }
    }
  }
  &.edit_package_tags {
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .tags {
      grid-column: 2 / -2;
    }
  };
  &.add_deploy_site {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
  }
  &.deploy_to_site {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      auto
      min-content
      min-content
      min-content
      auto
      var(--ui-margin-inline-end)
      ;
    & > .list.packages {
      & > * { // package
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .title {
          grid-column: 2 / 4;
          font-weight: 600;
        }
        & > .job {
          grid-column: 2 / 4;
        }
        & > .size_count {
          grid-column: 4;
          justify-self: end;
        }
        & > .size_suffix {
          grid-column: 5;
          justify-self: start;
          opacity: 0.7;
        }
        & > .icon {
          grid-column: 6;
        }
        & > .channel {
          grid-column: 7;
        }
        & > .empty {
          grid-column: 2 / -2;
        }
      }
    }
  }
  &.install_package {
    grid-template-columns:
      var(--ui-margin-inline-start)
      max-content
      1fr
      var(--ui-margin-inline-end)
      ;
  }
  &.me {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      1fr
      min-content
      var(--ui-margin-inline-end)
      ;
    & > .hint {
      grid-column: 2 / -2;
      color: var(--main-weak-color);
    }
    & > .list.sessions {
      & > * { // session
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          place-self: center;
          color: var(--main-weak-color);
        }
        & > .started, & > .expiration, & > .last_auth {
          grid-column: 3;
          color: var(--main-weak-color);
          & > .value {
            color: var(--main-color);
          }
        }
        & > .current {
          grid-column: 3;
          font-weight: 600;
        }
        & > .buttons {
          grid-column: 5;
          grid-row: 1 / span 2;
          place-self: center end;
        }
        & > .footer {
          grid-column: 3 / 6;
        }
        & > .empty, & > .loading, & > .error {
          grid-column: 3 / -2;
        }
      }
    }
    & > .list.credentials {
      & > * { // credential
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .profile_picture {
          grid-column: 2;
          grid-row: span 2;
          place-self: center normal;
          position: relative;
          width: 32px;
          height: 32px;
          border-radius: 4px;
        }
        & > .profile_link {
          grid-column: 3;
        }
        & > .profile_provider {
          grid-column: 3;
          white-space: nowrap;
          & > * {
            color: var(--main-weak-color);
          }
        }
        & > .created {
          grid-column: 4;
          grid-row: 1;
          color: var(--main-weak-color);
        }
        & > .last_used {
          grid-column: 4;
          grid-row: 2;
          color: var(--main-weak-color);
        }
        & > .buttons {
          grid-column: 5;
          grid-row: 1 / span 2;
          place-self: center end;
        }
        & > .footer {
          grid-column: 3 / 6;
        }
        & > .empty, & > .loading, & > .error {
          grid-column: 3 / -2;
        }
      }
    }
    & > .list.deploy_credentials {
      & > * { // deploy_credential
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          place-self: center;
          color: var(--main-weak-color);
        }
        & > .title {
          grid-column: 3;
        }
        & > .provider {
          grid-column: 3;
          white-space: nowrap;
          & > * {
            color: var(--main-weak-color);
          }
        }
        & > .buttons {
          grid-column: 5;
          grid-row: 1 / span 2;
          place-self: center end;
        }
        & > .footer {
          grid-column: 3 / 6;
        }
        & > .empty, & > .loading, & > .error {
          grid-column: 3 / -2;
        }
      }
    }
    & > .list.public_credentials {
      & > label { // credential
        display: grid;
        grid-template-columns: subgrid;
        place-items: baseline start;
        & > input[type='checkbox'] {
          grid-column: 2;
          justify-self: end;
        }
        & > .credential {
          grid-column: 3;
        }
      }
    }
  }
  &.auth, &.link_credential {
  }
  &.new_invite {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
  }
  &.add_deploy_credential {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      var(--ui-margin-inline-end)
      ;
    & > .hint {
      grid-column: 2 / -2;
    }
  }
  &.store_project {
    grid-template-columns:
      var(--ui-margin-inline-start)
      min-content
      1fr
      min-content
      min-content
      max-content
      var(--ui-margin-inline-end)
      ;
    & > .description {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
      background-color: var(--area-bg-color);
      border: 0.5px solid var(--border-color);
      & > .markdown {
        grid-column: 2 / -2;
      }
    }
    & > .list.owner {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        & > * {
          grid-column: 2 / -2;
          display: flex;
          align-items: baseline;
          gap: 8px;
        }
      }
    }
    & > .list.packages {
      & > * {
        display: grid;
        grid-template-columns: subgrid;
        align-items: baseline;
        & > .icon {
          grid-column: 2;
          grid-row: span 2;
          align-self: center;
        }
        & > .title {
          grid-column: 3;
          font-weight: 600;
        }
        & > .size_count {
          grid-column: 4;
          justify-self: end;
        }
        & > .size_suffix {
          grid-column: 5;
          justify-self: start;
          opacity: 0.7;
        }
        & > .buttons {
          grid-column: 6;
        }
        & > .tags {
          grid-column: 3 / -4;
        }
      }
    }
  }
  &.auth_progress {
  }
  &.docs {
    grid-template-columns:
      20px
      1fr
      max-content
      20px
      ;
    & > .header {
      & > .breadcrumbs {
        grid-column: 2;
      }
      & > .langs {
        grid-column: 3;
        display: flex;
        justify-content: end;
        gap: 4px;
        & > .selected {
          text-decoration: underline;
          cursor: default;
        }
      }
    }
    & > .markdown {
      grid-column: 2 / -2;
    }
  }
  &.experiment {
    @include sheet-anysize;
    & > video {
      grid-column: 2 / -2;
      &:focus {
        outline: none;
      }
    }
  }
}

input[type='text'], input[type='password'], input[type='number'], textarea {
  font-size: 18px;
  background: var(--input-bg-color);
  border: 0.5px solid var(--border-color);
  border-radius: 4px;
  box-shadow: var(--input-shadow);
  outline: none;
  padding: 4px;
  &:focus-visible {
    box-shadow: var(--input-glow);
  }
  &:invalid {
    border-color: var(--danger-color);
    &:focus-visible {
      box-shadow: var(--input-glow-danger);
    }
  }
}

.button {
  font-size: 16px;
  padding-block: 8px;
  padding-inline: 16px;
  border: 0.5px solid var(--border-color);
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px #0003;
  background: var(--button-bg-color);
  white-space: nowrap;
  &:disabled, &:disabled > * {
    cursor: not-allowed;
    color: var(--main-weak-color);
  }
  &.light {
    border: none;
    border-radius: 0;
    background: transparent;
    padding: 0;
    color: var(--main-weak-color);
    box-shadow: none;
    &:hover {
      color: var(--main-color);
    }
  }
}
button.button:enabled, a.button, label.button {
  cursor: pointer;
  text-decoration-skip-ink: none;
  &:hover {
    box-shadow: 0 0 6px -2px var(--button-active-shadow-color);
    text-decoration: underline;
    text-decoration-color: var(--main-underline-color);
  }
  &.danger:hover {
    box-shadow: 0 0 6px -2px var(--button-active-danger-shadow-color);
  }
  &.light:hover {
    box-shadow: none;
  }
  &.light.danger:hover {
    text-shadow: 0 0 5px var(--button-active-danger-shadow-color);
    text-decoration-color: var(--danger-color);
  }
  &:active, &.active {
    box-shadow: none;
    background: var(--button-active-bg-color);
  }
  &.danger:active, &.danger.active {
    box-shadow: none;
    background: var(--button-active-danger-bg-color);
  }
  &:focus-visible {
    outline-offset: -4px;
    outline: 1.5px dashed var(--button-active-shadow-color);
  }
  &.danger:focus-visible {
    outline: 1.5px dashed var(--button-active-danger-shadow-color);
  }
}

.dropdown {
  display: flex;
  place-items: baseline stretch;
  white-space: nowrap;
}
// undo default dialog styles
dialog.dropdown_menu {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  &::backdrop {
    background: transparent;
  }
}
.dropdown_menu {
  contain: content;
  border-radius: 6px;
  background: var(--area-bg-color);
  box-shadow: var(--sheet-shadow);
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 2px;
  place-content: start stretch;
  place-items: baseline stretch;
  z-index: 1;
  overflow: hidden auto;
  & > * {
    border: none;
    padding-block: 8px;
    padding-inline: 16px;
    text-align: start;
    &:not(:first-child) {
      border-block-start: 0.5px solid var(--border-color);
    }
    &.empty, &.loading {
      color: var(--main-weak-color);
    }
    &.error {
      color: var(--error-color);
    }
  }
  & > button {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    place-items: baseline normal;
    font-size: 16px;
    background: var(--area-bg-color);
    cursor: pointer;
    &:hover {
      &, & > *, & .icon {
        background: var(--selection-bg-color);
        color: var(--selection-color);
      }
    }
    &.danger:hover {
      &, & > *, & .icon {
        background: var(--selection-danger-color);
        background: var(--selection-danger-bg-color);
      }
    }
    & > * {
      grid-column: 2;
    }
    & > .icon {
      grid-column: 1;
      justify-self: center;
    }
  }
  &.align_start {
    inset-inline-start: 0;
  }
  &.align_end {
    inset-inline-end: 0;
  }

  // specific menus
  &.available_deploy_sites {
    grid-template-columns: min-content 1fr;
    column-gap: 4px;
    min-width: 400px;
    & > * {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      & > .picture {
        grid-column: 1;
        grid-row: span 2;
        max-width: 48px;
        max-height: 48px;
      }
      & > .title {
        grid-column: 2;
      }
      & > .url {
        grid-column: 2;
        white-space: nowrap;
        color: var(--main-weak-color);
      }
      &.empty, &.loading, &.error {
        grid-column: 1 / -1;
        white-space: nowrap;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  place-items: baseline stretch;
  gap: 4px;
  & > .filler {
    flex: 1 1 0%;
  }
  &.align_end {
    justify-content: end;
  }
  &.group {
    gap: 0;
    flex-wrap: nowrap;
    & > .button {
      border-radius: 0;
      &:first-child {
        border-start-start-radius: 6px;
        border-end-start-radius: 6px;
      }
      &:last-child {
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
      }
    }
    & > .dropdown {
      & > .button {
        border-radius: 0;
      }
      &:first-child > .button {
        border-start-start-radius: 6px;
        border-end-start-radius: 6px;
      }
      &:last-child > .button {
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
      }
    }
  }
}

.list {
  display: grid;
  & > * { // row
    grid-column: 1 / -1;
    background: var(--area-bg-color);
    padding-block: 10px;
    border-block-start: 0.5px solid var(--border-color);
    &:last-child {
      border-block-end: 0.5px solid var(--border-color);
    }
    &.selected {
      background-color: var(--selection-bg-color);
    }
  }
  & > a, & > .link { // row which is a link
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: var(--list-hover-bg-color);
      text-decoration: none;
    }
    &.selected:hover {
      background-color: var(--selection-bg-color);
    }
  }
}

label {
  color: var(--main-color);
  cursor: default;
}

.dialogs {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  contain: content;
}

.tabs {
  & > .header {
    background: var(--panel-bg-color);
    display: flex;
    align-items: baseline;
    padding-block-start: 8px;
    padding-inline: 8px;
    & > .tab {
      user-select: none;
      padding-block: 8px 8px;
      padding-inline: 12px;
      border-start-start-radius: 6px;
      border-start-end-radius: 6px;
      border-block-start: 0.5px solid var(--border-color);
      border-inline: 0.5px solid var(--border-color);
      background: var(--panel-bg-color);
      &.link {
        cursor: pointer;
      }
      &.active {
        background: var(--sheet-bg-color);
      }
    }
    & > .filler {
      flex-basis: 100%;
      align-self: stretch;
      height: 100%;
      padding-block: 4px 4px;
      padding-inline: 4px;
      background: var(--ink-inactive-color);
      & > * {
        padding-block: 4px;
        padding-inline: 8px;
        background: var(--main-bg-color);
      }
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
td, th {
  border: 2px solid var(--main-color);
  padding: 4px;
  text-align: start;
}
th {
  font-weight: 600;
  border-top: none;
  text-transform: uppercase;
}
td:first-child, th:first-child {
  padding-inline-start: 0;
  border-inline-start: none;
}
td:last-child, th:last-child {
  padding-inline-end: 0;
  border-inline-end: none;
}

.main {
  display: flex;
  justify-content: center;
  justify-items: stretch;
}

.header_panel {
  box-shadow: var(--sheet-shadow);
  background: var(--sheet-bg-color);
  border: var(--sheet-border);
  padding: 8px;
  display: grid;
  grid-template-columns:
    min-content
    1fr
    max-content
    min-content
  ;
  place-items: baseline stretch;
  gap: 10px;
  & > .app_name {
    grid-column: 1;
    font-size: 20px;
    font-weight: 400;
    color: var(--main-weak-color);
    & > .hint {
      color: var(--danger-color);
      font-weight: 700;
    }
  }
  & > .local_app {
    grid-column: 3;
    color: var(--main-weak-color);
  }
  & > .session {
    grid-column: 4;
    justify-self: end;
  }
}

.footer_panel {
  display: grid;
  grid-column: 1fr;
  justify-content: center;
  margin-block-start: 48px;
  margin-block-end: 8px;
  gap: 8px;
  & > .controls {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 8px;
  }
  & > .social_links {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 16px;
    & > * {
      white-space: nowrap;
    }
    & * {
      color: var(--main-weak-color);
    }
  }
}

.weak {
  color: var(--main-weak-color);
}

.icon {
  &.inline {
    margin-inline: 4px;
  }
  &.ok {
    color: var(--ok-color);
  }
  &.error {
    color: var(--danger-color);
  }
}

.upload-box {
  display: grid;
  grid-template-columns: subgrid;
  gap: 10px;
  & > .buttons {
    grid-column: 2 / -2;
  }
  & > .drop-area {
    grid-column: 2 / -2;
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-content: center;
    place-items: center;
    border: 2px dashed var(--border-color);
    border-radius: 4px;
    gap: 16px;
    padding: 16px;
    min-width: 480px;
    &.big {
      grid-template-columns: auto;
      min-height: 120px;
    }
    &.dropping {
      border: 2px solid var(--border-color);
    }
  }
  & > .entries {
    grid-column: 1 / -1;
    background: var(--area-bg-color);
    border-block: 0.5px solid var(--border-color);
    padding: 2px;
    display: grid;
    grid-template-columns:
      var(--ui-margin-inline-start)
      1fr
      min-content
      min-content
      var(--ui-margin-inline-end)
      ;
    column-gap: 10px;
    place-content: start stretch;
    user-select: none;
    cursor: default;
    min-height: 80px;
    & > .entry {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
      place-items: baseline start;
      border-radius: 2px;
      &.selected, &.selected:hover {
        background-color: var(--selection-bg-color);
        &, & * {
          color: var(--selection-color);
        }
      }
      &:hover {
        background-color: var(--list-hover-bg-color);
      }
      &.dropping {
        & > .name {
          outline: 2px dashed var(--selection-bg-color);
        }
      }
      & > .name {
        grid-column: 2;
        white-space: nowrap;
        margin-inline-start: calc(var(--tree-level) * 16px);
        & .icon {
          width: 20px;
          height: 16px;
        }
      }
      & > .size_count {
        grid-column: 3;
        justify-self: end;
      }
      & > .size_suffix {
        grid-column: 4;
        justify-self: start;
        opacity: 0.7;
      }
    }
  }
}

.file-upload, .directory-upload {
  user-select: none;
  & > input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}

.flow-ui {
  display: flex;
  gap: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  align-items: baseline;
  gap: 4px;
  & > * {
    border: 0.5px solid var(--border-color);
    border-radius: 8px;
    padding: 4px;
    background-color: var(--area-bg-color);
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    place-items: baseline normal;
    white-space: nowrap;
    &.empty {
      color: var(--main-weak-color);
    }
    & > div {
      font-size: 14px;
    }
  }
}
.list > * > .tags > * {
  background-color: transparent;
  & > div, & > .icon {
    color: var(--main-weak-color);
  }
}
.dropdown.dropdown_tags {
  & > .menu {
    grid-template-columns: 4px repeat(3, min-content) 4px;
    & > .item {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
      place-items: baseline normal;
      padding-inline: 0;
      & > .item_start {
        grid-column: 2;
      }
    }
  }
}

.inline_size {
  white-space: nowrap;
  & > .size_count {
    font-weight: 600;
    &::after {
      content: ' ';
    }
  }
}

.part_progress {
  border: 0.5px solid var(--border-color);
  border-radius: 4px;
  background: var(--area-bg-color);
  box-shadow: var(--input-shadow);
  padding: 2px;
  cursor: progress;
  & > * { // parts container
    display: flex;
    min-height: 16px;
    position: relative;
    & > * { // parts
      position: absolute;
      inset-block-start: 0;
      inset-block-end: 0;
      box-sizing: border-box;
      &.filled {
        --progress-color: var(--progress-normal-color);
        --progress-bg-color: var(--progress-normal-bg-color);
        border: 2px solid var(--progress-color);
        background-color: var(--progress-bg-color);
        box-shadow: 0 0 8px 0 var(--progress-bg-color);
        &.status-normal {
          transition: inset 300ms ease-in-out;
        }
        &.status-ok {
          --progress-color: var(--progress-ok-color);
          --progress-bg-color: var(--progress-ok-bg-color);
        }
        &.status-error {
          --progress-color: var(--progress-error-color);
          --progress-bg-color: var(--progress-error-bg-color);
        }
        &:first-child {
          border-start-start-radius: 2px;
          border-end-start-radius: 2px;
        }
        &:last-child {
          border-start-end-radius: 2px;
          border-end-end-radius: 2px;
        }
      }
    }
  }
}

.token_block {
  display: block;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
}

.markdown {
  & p {
    font-weight: 450;
    text-align: justify;
    hyphens: auto;
  }
  & > h1 {
    margin-block: 20px 5px;
  }
  & > h2 {
    margin-block: 20px 5px;
    border-block-end: 0.5px solid var(--border-color)
  }
  & > h3 {
    margin-block: 10px 5px;
    font-size: 16px;
    text-decoration: underline 1px solid var(--border-color);
  }
  & > h1, & > h2, & > h3 {
    & > a.anchor {
      opacity: 0;
    }
    &:hover > a.anchor {
      opacity: 1;
    }
  }
  & > ul {
    margin-block: 5px;
    padding-inline-start: 20px;
  }
  & code {
    background-color: var(--area-bg-color);
    border: 0.5px solid var(--border-color);
    border-radius: 4px;
    padding-inline: 4px;
  }
  & > .code {
    margin-block: 4px;
    margin-inline: -4px;
    background-color: var(--area-bg-color);
    border: 0.5px solid var(--border-color);
    border-radius: 4px;
    & > * {
      margin-block: 4px;
      margin-inline: 4px;
    }
  }
}
